import { ReactElement } from 'react';
import moment from 'moment';

import { APP_LINK_PAGE_NAME_WITH_ROUTE } from 'constants/externalApp.constant';
import { ELSCommonUIConstants } from 'components/common';
import { FeatureFlag } from 'models';
import { shouldRenderAssignmentDetailAppLinkUrl, renderAppLinkingAssignmentName } from 'services/app-linking.service';

import { ASSIGNMENT_TYPES, VALUE_NA, VALUE_NA_TEXT } from 'reports/cw/constants/report.constant';
import { AssignmentEngagementRecord, ASSIGNMENT_SCORING_TYPE_MAPPING, CWAssignment, CourseSection } from 'reports/cw/models';
import { asTimestringFormated } from 'reports/cw/services/report.service';
import { CatalogDto } from 'reports/ps/models';
import { getInteractiveReviewBody } from 'reports/cw/helpers/student-assignment.helper';
import { isNotGradeAssignment } from 'reports/cw/helpers/report.helper';

const dateFormat = ELSCommonUIConstants.momentDateFormat.datePrimary;

export const compareDate = (r1: AssignmentEngagementRecord, s2: AssignmentEngagementRecord): number => moment(r1.dueDate).diff(moment(s2.dueDate));

const getBody = (
  searchKeyword: string,
  assignmentTypeId: string,
  xLinkId: string,
  isBackToPreviousAppIconShown: boolean,
  assignmentType: string,
  assignmentId: number,
  courseSectionInfo: CourseSection,
  catalog: CatalogDto,
  assignments: CWAssignment[]
) => {
  let body = {
    performanceReportPage: APP_LINK_PAGE_NAME_WITH_ROUTE.CW_ASSIGNMENT_ENGAGEMENT.name,
    keptXLinkId: xLinkId,
    keptIsBackToPreviousAppIconShown: {
      isKept: true,
      value: isBackToPreviousAppIconShown
    },
    keptFilterData: [
      {
        actionName: 'setCWAssignmentEngagementTableSearch',
        value: searchKeyword
      },
      {
        actionName: 'setCWEngagementAssignmentTypeDropdownId',
        value: assignmentTypeId
      }
    ]
  };
  if (assignmentType === ASSIGNMENT_TYPES.INTERACTIVE_REVIEW.id) {
    body = { ...body, ...getInteractiveReviewBody(courseSectionInfo, catalog, assignments, assignmentId) };
  }
  return body;
};

export const renderName = (
  row: AssignmentEngagementRecord,
  featureFlags: Array<FeatureFlag>,
  courseId: string,
  xLinkId: string,
  isBackToPreviousAppIconShown: boolean,
  searchKeyword: string,
  assignmentTypeId: string,
  onRedirectToOutsideApp: Function,
  courseSectionInfo: CourseSection,
  catalog: CatalogDto,
  assignments: CWAssignment[]
): ReactElement => {
  const { id, name, assignmentType } = row;
  if (shouldRenderAssignmentDetailAppLinkUrl(row.assignmentType, featureFlags, courseId)) {
    const assignmentConfig = {
      id: id.toString(),
      name,
      type: assignmentType
    };
    const body = getBody(searchKeyword, assignmentTypeId, xLinkId, isBackToPreviousAppIconShown, assignmentType, id, courseSectionInfo, catalog, assignments);
    const renderAppLinkingAssignmentNameProps = {
      assignmentConfig,
      assignmentType,
      body,
      handleRedirectToOutsideApp: onRedirectToOutsideApp
    };
    return (
      <div title={name} className="c-cw-assignment-engagement-detail-table__cell-assignment-name">
        {renderAppLinkingAssignmentName(renderAppLinkingAssignmentNameProps)}
      </div>
    );
  }
  return (
    <div title={name} className="c-cw-assignment-engagement-detail-table__cell-assignment-name">
      <span>{name}</span>
    </div>
  );
};

export const renderDueDate = (row: AssignmentEngagementRecord): ReactElement => {
  const { dueDate, scoringType } = row;
  if (isNotGradeAssignment(scoringType) && !dueDate) {
    return <span>{VALUE_NA_TEXT}</span>;
  }
  if (!dueDate) {
    return <span>{VALUE_NA_TEXT}</span>;
  }
  return <span>{moment(dueDate).format(dateFormat)}</span>;
};

export const renderScoringType = (row: AssignmentEngagementRecord): ReactElement => {
  return <span>{ASSIGNMENT_SCORING_TYPE_MAPPING?.[row.scoringType] || row.scoringType}</span>;
};

export const renderClassAvgGrade = (row: AssignmentEngagementRecord): ReactElement => {
  const { percentCompletion, scoringType, classAvgScore } = row;
  if (percentCompletion === 1 && isNotGradeAssignment(scoringType)) {
    return <span>{VALUE_NA_TEXT}</span>;
  }
  if (!classAvgScore) {
    return <span>{VALUE_NA_TEXT}</span>;
  }
  return <span>{`${+(classAvgScore * 100).toFixed(0)}%`}</span>;
};

export const renderClassAvgTimeSpent = (row: AssignmentEngagementRecord): ReactElement => {
  const { classAvgTimeSpentSeconds } = row;
  if (classAvgTimeSpentSeconds === 0) {
    return <span>{VALUE_NA}</span>;
  }
  return <span>{asTimestringFormated(+classAvgTimeSpentSeconds.toFixed(0))}</span>;
};

export const renderPercentCompletion = (row: AssignmentEngagementRecord): ReactElement => {
  return <span>{`${+(row.percentCompletion * 100).toFixed(0)}%`}</span>;
};
