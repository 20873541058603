const dueDate = new Date().toISOString();

export const assignmentSummaryV2ByCourseId = {
  metrics: [
    {
      assignmentId: 270790,
      assignmentName: 'Issues and Changes Affecting Health Care Delivery',
      assignmentType: 'LESSONS',
      dueDate: dueDate,
      percentComplete: 0.8,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 1,
      gradePoints: 100,
      avgScore: 0.6428571428571428,
      percentAvgScore: 64.28571428571428,
      avgTimeSpent: 'PT9M9S',
      assignmentGradeType: 'SCORED',
      totalStudents: 5,
      studentsBelowAvgScore: 3,
      studyMode: false,
      maxTimeSpent: 'PT18M5S',
      minTimeSpent: 'PT25S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 270791,
      assignmentName: 'Public Health Nursing',
      assignmentType: 'LESSONS',
      dueDate: dueDate,
      percentComplete: 0.2,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 4,
      gradePoints: 100,
      avgScore: 1.0,
      percentAvgScore: 100.0,
      avgTimeSpent: 'PT5M49S',
      assignmentGradeType: 'PASS_FAIL',
      totalStudents: 5,
      studentsBelowAvgScore: 4,
      studyMode: false,
      maxTimeSpent: 'PT5M49S',
      minTimeSpent: 'PT5M49S',
      isPastDueAssignment: false
    },
    {
      assignmentId: 271047,
      assignmentName: 'Caring in Nursing',
      assignmentType: 'LESSONS',
      dueDate: '2024-07-29T16:59:59.999Z',
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 5,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'SCORED',
      totalStudents: 5,
      studentsBelowAvgScore: 5,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: true
    },
    {
      assignmentId: 271049,
      assignmentName: 'Culturally Competent Nursing Care',
      assignmentType: 'LESSONS',
      dueDate: dueDate,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 5,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 0.0,
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'SCORED',
      totalStudents: 5,
      studentsBelowAvgScore: 5,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: true
    },
    {
      assignmentId: 271040,
      assignmentName: 'TEACH for Nurses',
      assignmentType: 'EVOLVE_RESOURCE',
      dueDate: dueDate,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 5,
      gradePoints: 0,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 5,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 271042,
      assignmentName: 'PowerPoint Presentations',
      assignmentType: 'EVOLVE_RESOURCE',
      dueDate: dueDate,
      percentComplete: 0.0,
      totalStudentsInProgress: 0,
      totalStudentsNotStarted: 5,
      gradePoints: 0,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'NOT_GRADED',
      totalStudents: 5,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    },
    {
      assignmentId: 271655,
      assignmentName:
        'Health Promotion, Illness Prevention, and Levels of Preventive Care - AC4: has not completed an assignment and it is prior to the due date, it should appear as N/A',
      assignmentType: 'LESSONS',
      dueDate: dueDate,
      percentComplete: 0.0,
      totalStudentsInProgress: 1,
      totalStudentsNotStarted: 4,
      gradePoints: 100,
      avgScore: 0.0,
      percentAvgScore: 'NaN',
      avgTimeSpent: 'PT0S',
      assignmentGradeType: 'SCORED',
      totalStudents: 5,
      studentsBelowAvgScore: 0,
      studyMode: false,
      maxTimeSpent: 'PT0S',
      minTimeSpent: null,
      isPastDueAssignment: false
    }
  ],
  card: {
    totalAssignments: 28,
    totalCompletedAssignments: 1,
    highPerformanceStudents: 0,
    totalStudents: 5,
    disengagedStudents: 5
  }
};
