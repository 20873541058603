import { matchPath } from 'react-router';
import { orderBy, map, flatten } from 'lodash';
import { ADOBE_PRODUCT_CODE, FeatureFlagNames, PageNamePrefixes, RoutePath, USER_ROLE } from 'constants/app.constant';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { WindowMode, EOLSUser } from 'models';
import { Program } from 'reports/had/models';
import { PS_PATHS } from 'reports/ps/constants';

export const getWindowMode = (): WindowMode => {
  const { innerWidth } = window;
  const mode = { mobile: false, tablet: false, bigTablet: false, desktop: false, wide: false };
  if (innerWidth < 600) {
    return { ...mode, mobile: true };
  }
  if (innerWidth >= 600 && innerWidth < 900) {
    return { ...mode, tablet: true };
  }
  if (innerWidth >= 900 && innerWidth < 1060) {
    return { ...mode, bigTablet: true };
  }
  if (innerWidth >= 1060 && innerWidth < 1200) {
    return { ...mode, desktop: true };
  }
  if (innerWidth >= 1200) {
    return { ...mode, wide: true };
  }
  return mode;
};

export const getNumberInRange = (num: number, min: number, max: number): number => {
  if (max < min) {
    return NaN;
  }
  return Math.max(Math.min(num, max), min);
};

/**
 * Change productName in adobePageData to had in hesi dashboard or scwp in others.
 * @param pathName
 */
export const getAdobeProductCode = (pathName: string) => {
  let productName = ADOBE_PRODUCT_CODE.SHERPATH_COURSEWARE;
  const hesiPaths = Object.values(HAD_PATHS);
  for (let i = 0; i < hesiPaths.length; i += 1) {
    const matching = matchPath(pathName, {
      path: hesiPaths[i],
      exact: true,
      strict: true
    });
    if (matching) {
      productName = ADOBE_PRODUCT_CODE.HAD_DASHBOARD;
      break;
    }
  }
  return productName;
};

// 1. calculate the total and the percentage of the input array.
// 2. get the decimal of the calculated percentage.
// 3. sort the decimal by descending to get the adding queue (from biggest to smallest decimal).
// 4. round down the calculated percentage.
// 5. get the difference between the round down and the calculated percentage.
// 6. based on the adding queue order index, get the related round down percentage and adding 1.
// 7. repeat the adding to the queue until the difference is zero.
export const roundPercentageTotals = (numberArr: number[]): number[] => {
  const total = numberArr.reduce((totalNum, currentNum) => totalNum + currentNum, 0);
  const mappedNumberArrayWithObjectIndexDecimal = numberArr.map((num, index) => ({ index, value: num, decimalPercentage: ((num / total) * 100) % 1 }));
  const sortedDecimalInMappedArr = orderBy(mappedNumberArrayWithObjectIndexDecimal, ['decimalPercentage'], ['desc']);
  const percentageResults = numberArr.map(num => Math.floor((num / total) * 100));
  const totalPercentage = percentageResults.reduce((totalPer, currentPer) => totalPer + currentPer, 0);
  const differentPercent = 100 - totalPercentage;
  if (differentPercent !== 0) {
    for (let i = 0; i < differentPercent; i += 1) {
      const idx = sortedDecimalInMappedArr[i].index;
      percentageResults.splice(idx, 1, percentageResults[idx] + 1);
    }
  }
  return percentageResults;
};

export const checkPathMatchV2 = (paths: Array<string>, isExact = true) => {
  const pathName = window.location.hash.substring(1).split('#')?.[0];
  return (paths || []).some(path => {
    return matchPath(pathName, {
      path,
      exact: isExact,
      strict: true
    });
  });
};

export const checkPathMatch = (paths: Array<string>) =>
  (paths || []).some(path => {
    return matchPath(window.location.hash.substring(1), {
      path,
      exact: true,
      strict: true
    });
  });

export const getAllCwRouteString = (): string[] => {
  let allCwRoute = [];
  Object.values(PerformancePath).forEach(route => {
    if (typeof route === 'object') {
      allCwRoute = [...allCwRoute, ...Object.values(route)];
    } else {
      allCwRoute = [...allCwRoute, route];
    }
  });
  return [...allCwRoute, RoutePath.home];
};

export const checkCWLinkDisable = (isCWBentoBoxLinkShow: boolean): boolean => {
  return !isCWBentoBoxLinkShow;
};

export const checkEHRLinkDisable = (eaqTopicMappedHesiExamByCourseId: {}): boolean => {
  return (
    !!eaqTopicMappedHesiExamByCourseId &&
    !!Object.keys(eaqTopicMappedHesiExamByCourseId).length &&
    !!eaqTopicMappedHesiExamByCourseId[Object.keys(eaqTopicMappedHesiExamByCourseId)[0]]?.length
  );
};

export const checkHADLinkDisable = (hadPrograms: Program[]): boolean => {
  return !hadPrograms?.length;
};

export const checkPRDLinkDisable = (instructorId: number): boolean => {
  return !instructorId;
};

export const handleNavigationClick = (isDisabled: boolean, onNavigationClick?: Function) => {
  if (!onNavigationClick || isDisabled) {
    return false;
  }
  return onNavigationClick();
};

export const sherEvolCourseFromEolsUsers = (eolsUsers: EOLSUser[]) => {
  const courseSections = flatten(map(eolsUsers, 'courseSections')) || [];
  return courseSections.filter(({ entitlements = [] }) => entitlements.length > 0);
};

export const getTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isBreadcrumbBackTitleShown = (breadcrumbBackTitle: string): boolean => {
  const { erp, ps } = PageNamePrefixes;
  if (checkPathMatch([PS_PATHS.homePage]) && breadcrumbBackTitle === ps) {
    return false;
  }
  if (checkPathMatch([RoutePath.reportingPortal]) && breadcrumbBackTitle === erp) {
    return false;
  }
  return true;
};

export const openLinkInNewTab = (path: string) => {
  window.open(`/#${path}`, '_blank');
};

export const isStudent = (eolsUser: EOLSUser) => {
  return eolsUser?.role === USER_ROLE.STUDENT || eolsUser?.type === USER_ROLE.STUDENT;
};

export const getStudentUserFullName = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
  return `${lastName}, ${firstName}`;
};

export const callFunction = (func: Function, param: unknown) => {
  if (func && typeof func === 'function') {
    func(param);
  }
};

export const validURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(str);
};

export const convertObjectKeyFromSnakeToCamel = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => convertObjectKeyFromSnakeToCamel(item));
  }

  return Object.keys(obj).reduce((camelObj, key) => {
    const camelKey = key.replace(/_(\w)/g, (_, p1) => p1.toUpperCase());
    return { ...camelObj, [camelKey]: convertObjectKeyFromSnakeToCamel(obj[key]) };
  }, {});
};

export const combineCondition = (condition: boolean, ...conditions: boolean[]): boolean => {
  return conditions.reduce((acc, cur) => acc && cur, condition);
};

export const isEnabledMockService = () => {
  return localStorage.getItem(FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER) === 'true';
};
