const DB_NAME = 'PerformanceDashboard';
let dbInstance: IDBDatabase | null = null;

export enum Stores {
  ActionState = 'actionState'
}

export interface IndexedDBRecord {
  id: string;
  value: string;
}

export const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(Stores.ActionState)) {
        db.createObjectStore(Stores.ActionState, { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      dbInstance = request.result;
      resolve(dbInstance);
      dbInstance.onversionchange = () => {
        dbInstance.close();
      };
    };
    request.onerror = () => {
      reject(request.error);
    };
  });
};

export const addUpdateData = async (data: { id: string; value: string }): Promise<void> => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readwrite');
    const store = transaction.objectStore(Stores.ActionState);
    // Using "put" instead of "add" to update existing data if key exist and add new record if index doesn't exist.
    const request = store.put(data);

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

export const getData = async (id: string): Promise<IndexedDBRecord> => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(Stores.ActionState, 'readonly');
    const store = transaction.objectStore(Stores.ActionState);
    const request = store.get(id);

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};
