/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { getWindowMode } from 'helpers/app.helper';
import { WindowMode } from 'models';

interface StateResizeComponent {
  windowMode: WindowMode;
}

const withResizeScreen = WrappedComponent => {
  return class extends Component<any, StateResizeComponent> {
    constructor(props) {
      super(props);
      this.state = {
        windowMode: getWindowMode()
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimension);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimension);
    }

    updateDimension = () => {
      this.setState({
        windowMode: getWindowMode()
      });
    };

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
};

export default withResizeScreen;
