import { ReactElement, useState, useEffect, FC, useCallback } from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { APP_DEFAULT_NAV_ITEMS, RoutePath } from 'constants/app.constant';
import { WindowMode, NavItem, PageNavigation } from 'models';
import { checkPathMatch } from 'helpers/app.helper';
import { APP_LINK_PAGE_NAME_WITH_ROUTE, EXTERNAL_APP_INFO } from 'constants/externalApp.constant';
import HADPageNavigatorModal from 'reports/had/components/common/page-navigator/HADPageNavigatorModal';
import { appActions } from 'redux/ducks/app';
import { ehrPath } from 'reports/ehr/constants/ehr.constant';
import { AppLinkOutPostBody } from 'models/app-linking';
import ButtonMenu from '../button-menu/ButtonMenu';
import PageNavigatorModal from './PageNavigatorModal';
import withResizeScreen from '../with-resize-screen/withResizeScreen';
import withRedirectToOutsideApp from '../with-redirect-to-outside-app/withRedirectToOutsideApp';

export interface PageNavigatorPropsType {
  openModal: boolean;
  isShowEAQTopicReport: boolean;
  isCWLinkHADAndEHRChecking: boolean;
  handleCancel: () => void;
  windowMode: WindowMode;
  onRedirectToOutsideApp: Function;
  xLinkId: string;
  isBackToPreviousAppIconShown: boolean;
  isHADPath: boolean;
  isCWSubNavInEHRPageShow: boolean;
  appLinkOutPostBody: AppLinkOutPostBody;
  isCSApp: boolean;
  eaqTopicMappedHesiExamByCourseId?: {};
  isHesiReadinessApp?: boolean;
  currentAppLinkPage?: string;
  customAppLinkRedirectUrl?: string;
  studentNameDisplay?: string;
  isEhrReportingEnabled: boolean;
}

const EAQ_TOPIC_REPORT_NAME = 'EAQ Topic Report';
const EHR_NAME = 'HESI Speciality Exam Readiness';

export const renderNavButton = (name: string, onActionClick: Function, isLinkActive: boolean): ReactElement => {
  return (
    <button
      key={name}
      type="button"
      tabIndex={0}
      className={classNames('u-els-anchorize c-els-tab-group__item c-els-tab-group__item-link', { 'c-els-tab-group__item--active': isLinkActive })}
      onClick={() => onActionClick()}
    >
      <span className="c-els-tab-group__text">{name}</span>
    </button>
  );
};

const PageNavigator: FC<PageNavigatorPropsType> = ({
  openModal,
  isCWLinkHADAndEHRChecking,
  handleCancel,
  windowMode,
  eaqTopicMappedHesiExamByCourseId,
  isHesiReadinessApp,
  currentAppLinkPage,
  isHADPath,
  onRedirectToOutsideApp,
  studentNameDisplay,
  isShowEAQTopicReport,
  isCWSubNavInEHRPageShow,
  appLinkOutPostBody,
  isEhrReportingEnabled,
  isCSApp
}) => {
  const goToEAQReport = async () => {
    const body = {
      performanceReportPage: currentAppLinkPage
    };

    const linkConfig = {
      appName: EXTERNAL_APP_INFO.APP_LINKING.EAQ.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.EAQ.ACTION.PERFORMANCE_VIEW,
      isIncludeLinkHash: false
    };

    onRedirectToOutsideApp(linkConfig, { data: body });
  };

  const getInitialNavItems = (): PageNavigation => {
    if (isShowEAQTopicReport && !isCSApp) {
      const combinedNavItems = cloneDeep(APP_DEFAULT_NAV_ITEMS);
      const eaqTopicReportNavItem = {
        name: EAQ_TOPIC_REPORT_NAME,
        onClickCustomLink: goToEAQReport
      };
      combinedNavItems.default = [...combinedNavItems.default, eaqTopicReportNavItem];
      return combinedNavItems;
    }

    return APP_DEFAULT_NAV_ITEMS;
  };

  const [activePageName, setActivePageName] = useState('');
  const [navItems, setNavItems] = useState(() => getInitialNavItems());
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGetActiveName = useCallback((): void => {
    const mergedNavItems = [...navItems.default, ...navItems.expand, ...navItems.extraActive];
    mergedNavItems.forEach((item: NavItem) => {
      const { name, paths } = item;
      const match = paths ? checkPathMatch(paths) : false;
      if (match) {
        setActivePageName(name);
      }
    });
  }, [navItems.default, navItems.expand, navItems.extraActive]);

  useEffect(() => {
    handleGetActiveName();
  }, [handleGetActiveName]);

  useEffect(() => {
    if (studentNameDisplay) {
      setActivePageName(studentNameDisplay);
    }
  }, [studentNameDisplay]);

  useEffect(() => {
    if (!isCSApp && isEhrReportingEnabled) {
      const tempExpandNavItems = [];
      tempExpandNavItems.unshift({ name: EHR_NAME, paths: [PerformancePath.hesiReadinessReport.home], isAlwaysActive: isHesiReadinessApp });
      const tempNavItems = Object.assign({}, navItems, { expand: tempExpandNavItems });
      setNavItems(tempNavItems);
    }
  }, [eaqTopicMappedHesiExamByCourseId, isHesiReadinessApp, isEhrReportingEnabled, isCSApp]);

  const goToHesiReadinessPage = () => {
    const { performanceReportPage } = appLinkOutPostBody || {};
    if (performanceReportPage !== APP_LINK_PAGE_NAME_WITH_ROUTE.PERFORMANCE_HESI_READINESS.name) {
      dispatch(appActions.setIsCWSubNavInEHRPageShow(true));
    }
    history.push(RoutePath.ehr);
  };

  const goToUrlPage = (url: string): void => {
    history.push(url);
  };

  const renderNavItem = (navItem: NavItem): ReactElement => {
    const { isAlwaysActive, paths = [], onClickCustomLink, icon, name } = navItem;
    const isEAQTopicReportLink = onClickCustomLink && name === EAQ_TOPIC_REPORT_NAME && !isCSApp;
    if (isEAQTopicReportLink) {
      return renderNavButton(name, goToEAQReport, false);
    }

    const isHesiReadinessLink = name === EHR_NAME && !isCSApp;
    if (isHesiReadinessLink) {
      const isEHRPath = checkPathMatch(Object.values(ehrPath));
      return renderNavButton(name, goToHesiReadinessPage, isEHRPath);
    }

    const defaultPath = paths[0];
    const match = isAlwaysActive || checkPathMatch(paths);
    return (
      <button
        type="button"
        key={name}
        tabIndex={0}
        className={classNames('u-els-anchorize c-els-tab-group__item c-els-tab-group__item-link c-page-navigator__link-button', { 'c-els-tab-group__item--active': match })}
        onClick={() => goToUrlPage(defaultPath)}
      >
        <span className="c-els-tab-group__text c-page-navigator__link-text">
          {name}
          {icon && <ELSIcon name={icon} size="1x" />}
        </span>
      </button>
    );
  };

  const { expand } = navItems;
  const isExistExpandNavItems = !!expand.length;
  let showDefaultNavItems = true;
  if (isHesiReadinessApp) {
    showDefaultNavItems = isCWSubNavInEHRPageShow;
  }

  return (
    <>
      {!windowMode.mobile && !isHADPath && (
        <div className="u-els-background-color-n0 c-page-navigator">
          <ul className="u-max-width-1400 c-cw-report-content c-els-tab-group c-page-navigator__menu">
            {showDefaultNavItems &&
              Object.values(navItems?.default).map(item => {
                return renderNavItem(item);
              })}
            {isExistExpandNavItems && (
              <>
                {!isHesiReadinessApp && <ButtonMenu menuItems={Object.values(expand)} />}
                {Object.values(expand).map(item => {
                  return renderNavItem(item);
                })}
              </>
            )}
            {isCWLinkHADAndEHRChecking && (
              <li className="c-els-tab-group__item">
                <ELSInlineLoader />
              </li>
            )}
          </ul>
          <div className="c-els-divider c-els-divider--1o2" />
        </div>
      )}
      {openModal && !isHADPath && (
        <PageNavigatorModal navItems={navItems} handleCancel={handleCancel} activePageName={activePageName} isHesiReadinessApp={isHesiReadinessApp} goToEAQReport={goToEAQReport} />
      )}
      {openModal && isHADPath && <HADPageNavigatorModal handleCancel={handleCancel} isHesiReadinessApp={isHesiReadinessApp} />}
    </>
  );
};

export default withResizeScreen(withRedirectToOutsideApp(PageNavigator));
