import { rest } from 'msw';
import { studentsByEvolveUserName } from 'mock-data/performancereport/allCourses/students/prod';
import { assignmentSummaryV2ByCourseId } from 'mock-data/performancereport/assignmentSummaryV2/prod';
import { gradebookCourseSection } from 'mock-data/performancereport/gradebook/courseSection/prod';
import { assignmentEngagementReportV2Map } from 'mock-data/performancereport/assignmentEngagement/reportV2/prod';

export const prodUrl = 'https://eolsapi.elsevier.com/api/performancereport';
const courseId = '622453';

export const prodHandler = [
  rest.get(`${prodUrl}/allCourses/students`, (req, res, ctx) => {
    const queryParams = req.url.searchParams;
    const evolveUserName = queryParams.get('evolveUserName');

    if (!evolveUserName) {
      req.passthrough();
    }

    return res(ctx.status(200), ctx.json(studentsByEvolveUserName));
  }),
  rest.get(`${prodUrl}/assignmentSummaryV2`, (req, res, ctx) => {
    const queryParams = req.url.searchParams;
    const courseSectionId = queryParams.get('courseSectionId');
    if (!courseSectionId && courseSectionId === courseId) {
      req.passthrough();
    }

    return res(ctx.status(200), ctx.json(assignmentSummaryV2ByCourseId));
  }),
  rest.get(`${prodUrl}/gradebook/courseSection/${courseId}`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(gradebookCourseSection));
  }),

  rest.get(`${prodUrl}/assignmentEngagement/reportV2`, (req, res, ctx) => {
    const courseSectionId = req.url.searchParams.get('courseSectionId');

    if (!assignmentEngagementReportV2Map[courseSectionId]) {
      req.passthrough();
    }

    return res(ctx.status(200), ctx.json(assignmentEngagementReportV2Map[courseSectionId]));
  })
];
