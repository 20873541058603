// ====== Support IE browser ========
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// ==================================

import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { FeatureFlagNames } from 'constants/app.constant';
import App from './components/App';
import { worker } from './mocks/browser';

const enableMockServiceWorker = localStorage.getItem(FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER);
if (enableMockServiceWorker && enableMockServiceWorker === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass' // To bypass other API calls.
  });
}

if (process.env.LOG_A11Y === 'true' && process.env.NODE_ENV !== 'production') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000);
    render(<App />, document.getElementById('app'));
  });
} else {
  render(<App />, document.getElementById('app'));
}
