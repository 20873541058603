import { FeatureFlagNames } from 'constants/app.constant';
import { worker } from 'mocks/browser';
import { FeatureFlag } from 'models';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/ducks/app';
import { isFeatureEnabled } from 'services/feature-flag.service';

const MockServiceHandler = () => {
  const featureFlags: FeatureFlag[] = useSelector(appSelectors.getFeatureFlags);
  const enableMockService = isFeatureEnabled(featureFlags, FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER);
  const storageMockServiceWorker = localStorage.getItem(FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER);

  useEffect(() => {
    if (!enableMockService && storageMockServiceWorker === 'true') {
      localStorage.setItem(FeatureFlagNames.ENABLE_MOCK_SERVICE_WORKER, 'false');
      worker.stop();
    }
  }, [enableMockService, storageMockServiceWorker]);

  return null;
};

export default MockServiceHandler;
