import { Language, pendoAPIKey, RoutePath, SYSTEM_ID } from 'constants/app.constant';
import { Component } from 'react';
import { appActions, appSelectors } from 'redux/ducks/app';
import { connect } from 'react-redux';
import { ELSLoggingService, ELSPendoService } from '@els/els-ui-common-react';
import { FeatureFlag } from 'models';
import { CrosswalkUserDto } from 'models/user-management';
import { routerSelectors } from 'redux/ducks/route';
import { CourseSection } from 'reports/cw/models';
import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { hadSelectors } from 'reports/had/redux/ducks/had';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { shSelectors } from 'reports/sh/redux/ducks/sh';
import { checkPathMatchV2 } from 'helpers/app.helper';

interface PendoInitProps {
  courseSectionInfo: CourseSection;
  evolveUser: CrosswalkUserDto;
  evolveUserLoading: boolean;
  evolveUserHasBeenCalled: boolean;
  featureFlags: FeatureFlag[];
  isbn: string;
  programId: string;
  programName: string;
  userId: string;
  userRole: string;
  instructorId: number;
}

interface PendoInitState {
  isPendoAlreadyIntialized: boolean;
  isPendoScriptInjected: boolean;
}

class PendoInit extends Component<PendoInitProps, PendoInitState> {
  constructor(props: PendoInitProps) {
    super(props);
    this.state = {
      isPendoAlreadyIntialized: false,
      isPendoScriptInjected: false
    };
  }

  componentDidMount() {
    if (!this.state.isPendoScriptInjected && pendoAPIKey) {
      ELSPendoService.injectScript(pendoAPIKey).then(() => {
        this.setState({ isPendoScriptInjected: true });
      });
    }
  }

  componentDidUpdate() {
    if (this.state.isPendoScriptInjected && !this.state.isPendoAlreadyIntialized) {
      this.initializePendo(this.props);
    }
  }

  // ToDo: data manipulation logic here is shared with AdobeAnalyticsInit. Move to a parent component
  initializePendo = props => {
    const {
      courseSectionInfo,
      evolveUser,
      evolveUserLoading,
      evolveUserHasBeenCalled,
      isbn,
      isPendoAlreadyIntialized,
      programId,
      programName,
      userId,
      userRole,
      fetchEvolveUser,
      instructorId
    } = props;
    const windowPage = window?.pageData?.page;
    const courseId = courseSectionInfo?.id?.toString() || '';
    const prefix = SYSTEM_ID.EVOLVE;

    let accountId = courseSectionInfo?.institution?.id?.toString() || '';
    let accountName = courseSectionInfo?.institution?.name || '';

    if (userId && !evolveUser && !evolveUserLoading && !evolveUserHasBeenCalled) {
      fetchEvolveUser(userId);
    }

    const isUserHaveEvolveData = evolveUser || checkPathMatchV2(Object.values(SH_PATHS));

    if (isPendoAlreadyIntialized || !userId || !userRole || !isUserHaveEvolveData) {
      return;
    }

    const { pendo, pageData, pageDataTracker } = window;
    if (!pendo || !pageData || !pageDataTracker) {
      // Taking a bit of a race condition risk here checking for pendo, pageData, and pageDataTracker
      // These checks will not initiate a componentDidUpdate lifecycle when they are initialized so if
      // they are not here when we run this function there is a chance we never initialize pendo
      ELSLoggingService.warn('PendoInit', 'Failed to initialize Pendo: missing window.pendo || window.pageData || window.pageDataTracker');
      return;
    }

    const locationHash = window.location.hash.toLowerCase();

    // if we're on NCLEX, we might not have institution id, so we need to use program id
    if (locationHash === `#${RoutePath.had.toLowerCase()}`) {
      accountId = !accountId ? programId.toString() : accountId;
      accountName = !accountName ? programName : accountName;
    }

    // if we're on SH, we might not have institution id, so we need to use instructor id
    if (checkPathMatchV2(Object.values(SH_PATHS))) {
      accountId = accountId || instructorId?.toString();
      accountName = accountName || 'shadowhealth-user';
    }

    if (!accountId || !accountName) {
      return;
    }

    window.pendo.initialize({
      visitor: {
        accessType: userRole,
        courseId,
        id: `${prefix}:${evolveUser?.externalUserId}`,
        isbn,
        pageEnvironment: windowPage?.environment,
        pageLanguage: Language.DEFAULT_LOCALE,
        pageProduct: windowPage?.productName,
        webuserID: `${prefix}:${evolveUser?.externalUserId}`
      },
      account: {
        id: `${prefix}:${accountId}`,
        name: `${prefix}:${accountName}`
      }
    });

    this.setState({ isPendoAlreadyIntialized: true });
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  courseSectionInfo: courseSelectors.getCourseSectionInfo(state),
  evolveUser: appSelectors.getEvolveUser(state),
  evolveUserLoading: appSelectors.getEvolveUserLoading(state),
  evolveUserHasBeenCalled: appSelectors.getEvolveUserHasBeenCalled(state),
  featureFlags: appSelectors.getFeatureFlags(state),
  isbn: appSelectors.getIsbn(state),
  location: routerSelectors.getLocation(state),
  programId: hadSelectors.getProgramId(state),
  programName: hadSelectors.getProgramName(state),
  userId: appSelectors.getUserId(state),
  userRole: appSelectors.getUserRole(state),
  instructorId: shSelectors.getInstructorId(state)
});

const mapDispatchToProps = dispatch => ({
  fetchEvolveUser: userId => dispatch(appActions.fetchEvolveUser(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PendoInit);
